<template>
  <div v-if="signBook" class="sign-parapher">
    <EuiContainer style="height: calc(100vh - 70px);">
      <refuseDialog :signworkflows="refuseDialog" @closeDialog="refuseDialog = []" v-if="refuseDialog.length" />
      <SignDialog :signworkflows="signDialog" v-model="isOpen" :hasParaphs="hasParaphs" :hasSigns="hasSigns" :textZoneModels="textsModel"/>
      <EuiGrid gutters>
        <EuiGridRow>
          <EuiGridColumn>
            <div class="m-0 flex items-center">
              <EuiButton iconOnly size="large" variant="text" @click="goBack">
                <EuiIcon name="chevron_left" color="primary"/>
              </EuiButton>
              <EuiHeading :level="1" bold>{{ signBook.name }}</EuiHeading>
            </div>
          </EuiGridColumn>
        </EuiGridRow>
        <EuiGridRow>
          <EuiGridColumn width="1/3">
            <EuiGridRow>
              <EuiBlock class="pdf-list" title="Documents">
                <div class="pdf-item eui-u-flex eui-u-flex-col"
                     v-for="(pdf, docIndex) in signBook.documents"
                     :key="pdf.signBookDocumentId"
                     @click="selectedDocument = docIndex">
                  <div class="eui-a-navigationItem"
                       :class="selectedDocument === docIndex ? 'router-link-active' : ''">
                    <div class="eui-a-navigationItem__graphic">
                      <EuiIcon size="medium" name="file"/>
                    </div>
                    {{ pdf.name }}
                  </div>
                  <div class="textField eui-u-flex eui-u-flex-col eui-u-ml-6 eui-u-my-2" v-for="(textField, textIndex) in zonesList.filter(zone => zone.zone.zoneType === 'textfield' && zone.zone.position.docId === pdf.signBookDocumentId)" :key="textIndex">
                    <div class="eui-u-flex eui-u-items-center">
                      <EuiIcon name="error" :color="textsModel.find(model => model.id === textField.zone.id).model !== '' ? 'success' : 'warning'"/>
                      <div class="eui-u-ml-4">
                        <div class="page-number">{{ $t('components.templates.parapher.edit.page-label') }} {{ textField.zone.position.page }}</div>
                        <div class="placeholder">{{ textField.zone.name }} - {{ textsModel.find(model => model.id === textField.zone.id).model }}</div>
                      </div>
                    </div>
                    <!--<a :href="`#anchor-${textField.zone.id}`">-->
                      <EuiButton @click="scrollToZone({ index: docIndex, zoneId: textField.zone.id, zoneType: 'textfield' })" class="eui-u-w-fit-content eui-u-py-1" variant="raised" color="primary">Renseigner</EuiButton>
                    <!--</a>-->
                  </div>
                </div>
              </EuiBlock>
            </EuiGridRow>
            <EuiGridRow class="eui-u-mt-4">
              <EuiBlock :title="$t('components.templates.parapher.edit.signatories')">
              <div v-for="item in signBook.steps"
                   :key="item.signBookStepId"
                   class="flex items-center">
                <div class="flex flex-col mt-1 mr-4 self-start">
                  <EuiIcon v-if="item.executionDate" name="check_circle" color="success"/>
                  <EuiIcon v-else name="error" color="warning"/>
                </div>
                <div class="flex flex-col">
                  <div class="font-bold">{{ item.fullname }}</div>
                  <div>
                    <template v-if="item.executionDate">
                      {{ $t('sign.signedOn') }} : {{ $d(new Date(item.executionDate.replace(/-/g, '/')), 'short') }}
                    </template>
                    <template v-else>
                      {{ $t('sign.pending') }}
                    </template>
                  </div>
                </div>
              </div>
            </EuiBlock>
            </EuiGridRow>
          </EuiGridColumn>
          <EuiGridColumn>
            <div class="es-pdf-viewer"  style="height: 800px;">
              <EuiSignPDFViewer v-if="currentPdf !== ''"
                                    :title="signBook.documents[selectedDocument].name"
                                    :pdf="currentPdf"
                                    base64
                                    :scrollToZone.sync="zoneId"
                                    ref="PDFViewer"
                                    :id="signBook.documents[selectedDocument].signBookDocumentId"
                                    :currentPage.sync="currentPage"
                                    :zones.sync="zonesList"
                                    @errors="onerror"
                                    @document-rendered="onDocumentRendered"
                                    @page-count="updatePageCount"
                                    @scale-change="updateScale"
                                    :textsModel.sync="textsModel"
                                    @download="downloadDoc()"/>
            </div>
          </EuiGridColumn>
        </EuiGridRow>
      </EuiGrid>
    </EuiContainer>
    <EuiBottomBar :class="bottomBarStyle">
      <EuiButton v-if="isTrue(signBook.allowDecline)"
                 variant="outlined"
                 color="primary"
                 class="mr-3"
                 @click="refuseDialog.push(signBook)">
        {{ $t('signworkflows.toolbar.refuse') }}
      </EuiButton>
      <EuiButton v-if="signBook.documents && signBook.documents.length > 1"
                 variant="outlined"
                 color="primary"
                 class="mr-3"
                 :disabled="signBook.documents && (selectedDocument === signBook.documents.length -1)"
                 @click="goToDocument">
        {{ $t('button.nextDocument') }}
      </EuiButton>
      <EuiButton :disabled="isSignButtonDisabled" variant="raised" color="primary" @click="sign(signBook)">
        {{ (!this.hasParaphs && !this.hasSigns) ? $t('button.validate') : $t('button.sign') }}
        <EuiTooltip v-if="isSignButtonDisabled" :content="errorMessage" placement="top">
          <template slot="anchor">
            <EuiIcon class="eui-u-ml-4" size="medium" name="help"/>
          </template>
        </EuiTooltip>
      </EuiButton>
    </EuiBottomBar>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'Sign',
  props: {
    signBookId: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    EuiSignPDFViewer: () => import(/* webpackChunkName: "PDFViewer" */ '../../components/PDFViewerSign/SignPDFViewer'),
    SignDialog: () => import(/* webpackPrefetch: true */ '../../pages/signworkflows/dialog/signature/SignDialog'),
    refuseDialog: () => import(/* webpackPrefetch: true */ '../../pages/signworkflows/dialog/refuseDialog'),
  },
  data() {
    return {
      scale: undefined,
      isContentLoaded: false,
      signBook: {},
      signDialog: [],
      refuseDialog: [],
      selectedDocument: 0,
      pdf: {
        object: {},
        renderers: [],
        currentPage: 1,
        totalPage: 1,
        signZone: { viewport: undefined },
      },
      zoneId: undefined,
      currentPdf: '',
      currentPage: 0,
      pages: undefined,
      pageCount: undefined,
      zonesList: [],
      textsModel: [],
    };
  },
  computed: {
    ...mapState({
      isGuest: (state) => state.ModuleEdocSign.auth.session.guest,
      isSidenavOpen: (state) => state.ModuleEdocSign.ui.isSidenavOpen,
      isMobileView: (state) => state.application.responsive.xs,
      isMiniVariant: (state) => state.ModuleEdocSign.ui.isMiniVariant,
    }),
    ...mapGetters({
      user: 'ModuleEdocSign/auth/user',
    }),
    isOpen: {
      get() {
        return this.signDialog && this.signDialog.length > 0;
      },
      set(value) {
        if (!value) {
          this.signDialog = [];
        }
      },
    },
    isSignButtonDisabled() {
      const noModelTextfields = this.textsModel.filter(field => field.model === '');
      return noModelTextfields.length > 0;
    },
    bottomBarStyle() {
      return {
        '-mini': (!this.isMobileView && this.isSidenavOpen && this.isMiniVariant) || this.isGuest,
        '-default': this.isSidenavOpen && !this.isMiniVariant && !this.isGuest,
      };
    },
    errorMessage() {
      const noModelTextfieldsId = this.textsModel.filter(field => field.model === '').map(item => item.id);
      const noModelTextfields = this.zonesList.filter(item => item.zone.zoneType === 'textfield' && noModelTextfieldsId.indexOf(item.zone.id) >= 0);

      let message = this.$i18n.tc('signworkflows.sign.error.emptyTextfield', noModelTextfields.length);
      message = message + '<ul>';
      for (let i = 0; i < noModelTextfields.length; i++) {
        message = message + '<li>' + noModelTextfields[i].zone.name + '</li>';
      }
      message = message + '</ul>';
      return message;
    },
    hasParaphs() {
      return this.zonesList.filter(zone => zone.zone.zoneType === 'paraph').length > 0;
    },
    hasSigns() {
      return this.zonesList.filter(zone => zone.zone.zoneType === 'signature').length > 0;
    },
  },
  watch: {
    isContentLoaded() {
      this.selectedDocument = 0;
      this.currentPdf = this.signBook.documents[this.selectedDocument].fileContent;
    },
    selectedDocument() {
      this.currentPdf = this.signBook.documents[this.selectedDocument].fileContent;
    },
  },
  methods: {
    ...mapActions({
      getSignBookById: 'ModuleEdocSign/parapheurs/getSignBookById',
      getDocContent: 'ModuleEdocSign/parapheurs/getDocContent',
      downloadDoc: 'ModuleEdocSign/parapheurs/downloadDoc',
      getArchive: 'ModuleEdocSign/parapheurs/getArchive',
      getUserInfos: 'ModuleEdocSign/auth/getUserInfos',
      pushAlert: 'application/pushAlert',
      findById: 'ModuleEdocSign/settingsUsers/findById',
    }),
    scrollToZone(zone) {
      this.selectedDocument = zone.index;
      setTimeout(() => {
        if (zone.zoneType === 'paraph') {
          this.$refs.PDFViewer.scrollToClass('eui-o-advancedPDFViewer__band');
        } else {
          this.zoneId = zone.zoneId;
        }
      }, 100);
    },
    goToDocument() {
      if (this.selectedDocument < this.signBook.documents.length - 1) {
        this.selectedDocument += 1;
      }
    },
    isTrue(boolean) {
      return (boolean === true || boolean === 'true' || boolean === '1' || boolean === 1);
    },
    goBack() {
      if (this.signBook.finalizedDate === null) {
        if (!this.$store.state.ModuleEdocSign.auth.session.guest) {
          this.$router.push({ path: '/signworkflows/waiting-for-action' });
        } else {
          this.$router.push({ name: 'guestSignworkflows' });
        }
      } else {
        this.$router.push({ name: 'guestSignworkflows.access' });
      }
    },
    downloadDocuments() {
      this.signBook.documents.forEach((document) => {
        this.downloadDoc([document.signBookDocumentId, document.name]);
      });
    },
    downloadDoc() {
      const linkSource = `data:application/pdf;base64,${this.signBook.documents[this.selectedDocument].fileContent}`;
      const downloadLink = document.createElement('a');
      const fileName = this.signBook.documents[this.selectedDocument].name;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    getDocument(doc) {
      const document = JSON.parse(JSON.stringify(doc));
      return this.getDocContent({ id: doc.signBookDocumentId })
        .then((response) => {
          document.fileContent = response.fileContent;
          return document;
        });
    },
    updatePage(payload) {
      this.currentPage = payload;
    },
    onDocumentRendered(pages) {
      this.pages = pages;
    },
    updatePageCount(pageCount) {
      this.pageCount = pageCount;
    },
    updateScale($event) {
      this.scale = $event;
    },
    onerror(errors) {
      for (let i = 0; i < errors.length; i++) {
        this.pushAlert({
          message: errors[i].message,
          timeout: 5000,
          type: 'error',
        });
      }
      console.log('ERROR', errors);
    },
    sign(signBook) {
      !this.hasParaphs && !this.hasSigns ? signBook.hasOnlyTextFields = true : signBook.hasOnlyTextFields = false;
      this.signDialog.push(signBook);
    },
  },
  async created() {
    if (this.user === undefined) {
      this.getUserInfos();
    }
    this.getSignBookById(this.signBookId).then(async(response) => {
      this.signBook = response;
      let promise;
      this.signBook.documents.forEach((document, index) => {
        if (index === 0) {
          promise = this.getDocument(document).then((response) => {
            this.signBook.documents[index] = response;
            return response;
          });
        } else {
          this.getDocument(document).then((response) => {
            this.signBook.documents[index] = response;
            return response;
          });
        }
      });
      await promise;
      this.isContentLoaded = true;
      if (this.signBook.steps) {
        this.signBook.steps.forEach((step) => {
          if (step.userCanSign) {
            const { positions } = JSON.parse(step.signatureParams);
            if (positions.length > 0) {
              for (let j = 0; j < positions.length; j++) {
                const position = positions[j];
                position.viewport = undefined;
                position.docId = position.docId.split('_').pop();
                delete position.type;

                const tmpZone = {
                  id: Math.random().toString(36).slice(4),
                  position,
                  zoneType: 'signature',
                  user: { fullname: step.fullname },
                  pagePoint: undefined,
                };
                this.zonesList.push({ zone: tmpZone });
              }
            }
            step.captiveZones.forEach((zone) => {
              if (zone.position) {
                let tmpZone = {};
                tmpZone.id = zone.id;
                tmpZone.zoneType = zone.zoneType;
                tmpZone.position = JSON.parse(JSON.stringify(zone.position));
                tmpZone.user = { fullname: step.fullname };
                tmpZone.pagePoint = undefined;
                if (zone.name) {
                  tmpZone.name = zone.name;
                  this.textsModel.push({id: zone.id, model: ''});
                }
                this.zonesList.push({ zone: tmpZone });
              } else {
                zone.positions.forEach((uniqueZone) => {
                  let tmpZone = {};
                  tmpZone.id = zone.id;
                  tmpZone.zoneType = zone.zoneType;
                  tmpZone.position = JSON.parse(JSON.stringify(uniqueZone));
                  tmpZone.user = { fullname: step.fullname };
                  tmpZone.pagePoint = undefined;
                  tmpZone.isInvisibleSignature = uniqueZone.isInvisibleSignature;
                  this.zonesList.push({ zone: tmpZone });
                  // }
                });
              }
            });
          }
        });
      }
    });
  },
};
</script>
<style lang="scss">
.eui-a-button {
  --main-bg-color: brown;
}
</style>
